export const authConstants = {
  SET_AUTH_INIT: "SET_AUTH_INIT",
  SET_AUTH_DATA: "SET_AUTH_DATA", // This will hold auth data.
  SET_AUTH_SUCCESS: "SET_AUTH_SUCCESS", // This will hold a boolean value for login
  SET_AUTH_ERROR: "SET_AUTH_ERROR", // This will hold error value.
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN", // This constant hold decoded value of Token.
  SET_AUTH_ACCOUNT_ID: "SET_AUTH_ACCOUNT_ID", // This constant hold web3 account ID.
  OKTA_CLIENT_ID: "0oa1rb6fqucaeGdtI0h8",
  OKTA_ISSUER: "https://applogin.jll.com/oauth2/aus1hwrinhqOQpF8n0h8",
  OKTA_REDIRECT: "/callback",
  OKTA_LOGOUT_REDIRECT: "https://applogin.jll.com/logout",
};
